import React, { useState, useEffect, Suspense, useReducer } from 'react';
import L from 'leaflet';
import http, {
  REACT_APP_WSURL,
  baseAPIServerUrl,
  baseAPIurl,
} from './services/httpService';
import web from './services/configService';
import Loaderapp from './common/loaderapp';
import ContactAdministrator from './common/contactAdministrator';
import {
  AppDataProvider,
  DEGlobalContext,
  WindowLiveGlobalContext,
} from './common/appDataContext';

//This is for Template Data for UI
const ConfigTemplate = {
  Domain: '',
  BrandId: '',
  TemplateName: '',
  FavIcon: '',
  Logo: '',
  Background: '',
  Fonts: '',
  PaymentType: '',
  CustomerName: '',
  customTemplate: true,
  needSetup: false,
  MapConfig: {},
};
let ComponentTemplate = React.lazy(() => import(`./sc/MainApp`));

/** Reducer ************************************************************************* */
const initialStateDE = {
  params: {
    dashboard: {},
    inefficiency: {},
  },
  data: {
    dashbaord: [],
    inefficiency: [],
  },
};

const reducerDE = (stateDE, action) => {
  switch (action.type) {
    case 'setDashboard':
      const data1 = stateDE.data;
      data1.dashbaord = action.payLoad.data; //data come from compoenent ex: dashboard
      return { ...stateDE, data1 };
    case 'setInefficiency':
      const { data } = stateDE;
      data.inefficiency = action.payLoad.data; //data come from compoenent ex: inefficiency
      return { ...stateDE, data };
    default:
      throw new Error();
  }
};

/************************************************************************************ */

/** Reducer for Window Live ********************************************************* */
const intialStateWindowLive = {
  URLWS: REACT_APP_WSURL,
  params: {
    selectedVehicles: [],
  },
  channels: {
    statusClustering: false,
    clustering: [],
    vehicles: [],
  },
  data: [],
  properties: {
    icons: {
      truckfrontmoving: L.icon({
        iconUrl: '/images/default/clustering/truckfrontmoving.svg',
        iconSize: [24, 24],
        iconAnchor: [12, 5],
      }),
      truckfrontstationary: L.icon({
        iconUrl: '/images/default/clustering/truckfrontstationary.svg',
        iconSize: [24, 24],
        iconAnchor: [12, 5],
      }),
      truckfrontstopped: L.icon({
        iconUrl: '/images/default/clustering/truckfrontstopped.svg',
        iconSize: [24, 24],
        iconAnchor: [12, 5],
      }),
    },
    popUppositions: [],
  },
};

const reducerWindowLive = (stateWindowLive, action) => {
  switch (action.type) {
    case 'setWindowsPopupPosition':
      const WPPosition = stateWindowLive.properties;
      const { popUppositions } = WPPosition;
      if (popUppositions.length > 0) {
        const WPnewPosition = popUppositions.map((itemPosition) => {
          if (`${itemPosition.name}` === `${action.payLoad.name}`) {
            itemPosition.values = action.payLoad.values;
          }
          return itemPosition;
        });
        WPPosition.popUppositions = WPnewPosition;
      } else {
        WPPosition.popUppositions.push(action.payLoad);
      }
      return {
        ...stateWindowLive,
        positions: WPPosition,
      };
    case 'closeWindowLive':
      const closeWindowLive = stateWindowLive.params;
      const closeWindowLiveFilter = closeWindowLive.selectedVehicles.filter(
        (item) => item.dataChannel.VehicleId !== action.payLoad.value
      );
      closeWindowLive.selectedVehicles = closeWindowLiveFilter;

      /********* REMOVE THE Channels ****************** */
      const removeChannelWindowsPopup = stateWindowLive.channels;
      const filterRemoveChannelWindowsPopup = removeChannelWindowsPopup.vehicles.filter(
        (itemFilterWP) => `${itemFilterWP.name}` !== `${action.payLoad.value}`
      );
      removeChannelWindowsPopup.vehicles = filterRemoveChannelWindowsPopup;
      /************************************************ */
      return {
        ...stateWindowLive,
        params: closeWindowLive,
        channels: removeChannelWindowsPopup,
      };

    case 'setWindowsPopup':
      const payLoadWindowsPopup = action.payLoad.data;
      const stateWindowPupupParams = stateWindowLive.params;

      if (stateWindowPupupParams.selectedVehicles.length > 0) {
        const paramsSelectedVehiclesFilter = stateWindowPupupParams.selectedVehicles.filter(
          (item) =>
            item.dataChannel.VehicleId ===
            payLoadWindowsPopup.dataChannel.VehicleId
        );

        if (paramsSelectedVehiclesFilter.length === 0) {
          stateWindowPupupParams.selectedVehicles.push(payLoadWindowsPopup);
        }
      } else {
        stateWindowPupupParams.selectedVehicles.push(payLoadWindowsPopup);
      }
      /**************************Create Own Channel **************************************/
      const channelWindowsPopup = stateWindowLive.channels;
      const filterChannelWindowsPopup = channelWindowsPopup.vehicles.filter(
        (itemFilterWP) =>
          `${itemFilterWP.name}` ===
          `${payLoadWindowsPopup.dataChannel.VehicleId}`
      );
      //Check if popupOpen
      if (payLoadWindowsPopup.status) {
        if (filterChannelWindowsPopup.length === 0) {
          //Create New Channel
          const newVehicleWS = new WebSocket(stateWindowLive.URLWS);
          channelWindowsPopup.vehicles.push({
            name: `${payLoadWindowsPopup.dataChannel.VehicleId}`,
            Websocket: newVehicleWS,
            value: payLoadWindowsPopup.params.value,
          });
        }
      }
      /********************************************************************************* */
      return {
        ...stateWindowLive,
        params: stateWindowPupupParams,
        channels: channelWindowsPopup,
      };

    case 'createChannelClustering':
      const channelState = stateWindowLive.channels;
      const { statusClustering } = channelState;
      if (!statusClustering) {
        const newClusteringWS = new WebSocket(stateWindowLive.URLWS);
        channelState.clustering.push({
          name: 'clustering',
          Websocket: newClusteringWS,
          value: action.payLoad.value,
        });
        channelState.statusClustering = true;
      }
      return {
        ...stateWindowLive,
        channels: channelState,
      };
    case 'removeChannelClustering':
      const removeChannelWS = stateWindowLive.channels;
      //const { clustering } = removeChannelWS;
      // const newRemoveClusteringChannel = clustering.filter(
      //   item => item.name !== action.payLoad.channelName
      // );
      removeChannelWS.statusClustering = false;
      removeChannelWS.clustering = [];
      return {
        ...stateWindowLive,
        channels: removeChannelWS,
      };
    default:
      throw new Error();
  }
};
// stateWindowLive, dispatchWindowLive;
/************************************************************************************* */

/**
 * Main App
 */
const App = (props) => {
  const [stateDE, dispatchDE] = useReducer(reducerDE, initialStateDE); // hook reducer for manage data dashboard & effice
  const [stateWindowLive, dispatchWindowLive] = useReducer(
    reducerWindowLive,
    intialStateWindowLive
  ); // hook reducer for manage data live tracking per vehicle

  const [template, setTemplate] = useState(ConfigTemplate);
  const [isLoading, setIsLoading] = useState(true);
  const [isValidSession, setIsValidSession] = useState(true);

  useEffect(() => {
    web
      .getCustomerPortals()
      .then((responses) => {
        const { status, data: TemplateData, needSetup } = responses;
        const CustomerPortals = http.decodeJwt(TemplateData.CustomerPortals);
        const PortalSettings = http.decodeJwt(TemplateData.PortalSettings);
        const LanguageData = http.decodeJwt(TemplateData.LanguageData);
        if (status) {
          ConfigTemplate.Domain = CustomerPortals.Domain;
          ConfigTemplate.BrandId = CustomerPortals.Brand
            ? CustomerPortals.Brand
            : null;
          ConfigTemplate.TemplateName = CustomerPortals.TemplateName;
          ConfigTemplate.Logo = CustomerPortals.Logo;
          ConfigTemplate.Background = CustomerPortals.Background;
          ConfigTemplate.FavIcon = CustomerPortals.FavIcon;
          ConfigTemplate.Fonts = CustomerPortals.Fonts;
          ConfigTemplate.PaymentType = CustomerPortals.PaymentType
            ? CustomerPortals.PaymentType
            : null;
          ConfigTemplate.CustomerName = CustomerPortals.CustomerName
            ? CustomerPortals.CustomerName
            : null;
          ConfigTemplate.CustomerId = CustomerPortals.CustomerId
            ? CustomerPortals.CustomerId
            : null;
          ConfigTemplate.customTemplate = false;
          ConfigTemplate.needSetup = needSetup;
          ConfigTemplate.PortalSettings = PortalSettings;
          ConfigTemplate.languageText = LanguageData;
          ConfigTemplate.IsPushNotificationEnabled = TemplateData.IsPushNotificationEnabled
            ? TemplateData.IsPushNotificationEnabled
            : 0;
          if (!CustomerPortals.custom) {
            ConfigTemplate.customTemplate = false;
          } else {
            ConfigTemplate.customTemplate = true;
          }
          ConfigTemplate.IsCustomerPortal = CustomerPortals.IsCustomerPortal;
          ConfigTemplate.DomainId = CustomerPortals.DomainId
            ? CustomerPortals.DomainId
            : null;
          const MapConfig = http.getMapConfig()
            ? JSON.parse(http.getMapConfig())
            : {};
          const mapCnf = {
            RME_API:
              MapConfig.length === 0 ? '' : MapConfig.REACT_APP_HERE_RME_API,
            APP_ID: MapConfig.length === 0 ? '' : MapConfig.REACT_APP_ID_HERE,
            CODE_HERE:
              MapConfig.length === 0 ? '' : MapConfig.REACT_APP_CODE_HERE,
            WEATHER_API: `${baseAPIurl}/Map/Weather`,
            APP_ID_WEATHER:
              MapConfig.length === 0 ? '' : MapConfig.REACT_APP_ID_HERE_WEATHER,
            CODE_HERE_WEATHER:
              MapConfig.length === 0
                ? ''
                : MapConfig.REACT_APP_CODE_HERE_WEATHER,
            GAPIKEY: MapConfig.length === 0 ? '' : MapConfig.REACT_APP_GAPIKEY,
            INCIDENT_API: `${baseAPIurl}/Map/LiveIncident`,
          };
          ConfigTemplate.MapConfig = mapCnf;
          setTemplate({ ...ConfigTemplate });
          setIsLoading(false);
        } else {
          setTemplate({ ...ConfigTemplate, needSetup: true });
        }
      })
      .catch((error) => {
        sessionStorage.clear();
      });

    return () => {};
  }, []);

  useEffect(() => {
    const User = http.getCurrentUserData();
    if (User !== undefined && User !== null) {
      ConfigTemplate.CurrentUser = User.User;
      ConfigTemplate.Permissions = http.getMenu();
      ConfigTemplate.AllPermissions = http.getAllPermissions();
      //TD-611
      if (User.DIRECTED_customer) {
        ConfigTemplate.CustomerId = parseInt(
          User.DIRECTED_customer.DefaultCustomer
        );
      }
    }
    return () => {};
  }, []);

  //Load The Template
  const url = window.location.pathname;
  if (url.includes('admin')) {
    ComponentTemplate = React.lazy(() => import(`./admin/appAdmin`));
    return (
      <React.Fragment>
        <Suspense fallback={<Loaderapp Active={isLoading} />}>
          <AppDataProvider value={template}>
            <DEGlobalContext.Provider value={[stateDE, dispatchDE]}>
              <ComponentTemplate />
            </DEGlobalContext.Provider>
          </AppDataProvider>
        </Suspense>
      </React.Fragment>
    );
  } else if (template) {
    http.setHeaderToken(http.getCurrentTokenKeyRaw());
    if (template.TemplateName) {
      //Load Dynamic Component
      ComponentTemplate = React.lazy(() =>
        import(`./${template.TemplateName.toLowerCase()}/MainApp`)
      );

      //Return The Component
      return (
        <React.Fragment>
          <Suspense fallback={<Loaderapp Active={isLoading} />}>
            <AppDataProvider value={template}>
              <WindowLiveGlobalContext.Provider
                value={[stateWindowLive, dispatchWindowLive]}
              >
                <DEGlobalContext.Provider value={[stateDE, dispatchDE]}>
                  <ComponentTemplate />
                </DEGlobalContext.Provider>
              </WindowLiveGlobalContext.Provider>
            </AppDataProvider>
          </Suspense>
        </React.Fragment>
      );
    } else {
      if (template.needSetup) return <ContactAdministrator />;
    }
    return <Loaderapp Active={isLoading} />;
  }
  return <Loaderapp Active={isLoading} />;
};
export default App;
